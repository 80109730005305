import React, { useEffect } from "react"
import { navigate } from "gatsby"
import {
  makeStyles,
  useMediaQuery,
  Grid,
  Typography,
  Modal,
} from "@material-ui/core"

import { GlobalStateContext } from "../../../context/GlobalContextProvider"

import GoRegistration from "../../button/goRegistration"

import Mokka from "../../../../static/svg/mokka.svg"
import MokkaInfo from "../../../../static/svg/mokkaInfo.svg"
import MokkaCross from "../../../../static/svg/mokkaCross.svg"

import MokkaIframeRegistration from "../../mokkaIframeRegistration"
import Tinkoff from "../../button/tinkoff"
import DenumButton from "../../button/denum"
import { useState } from "react"
import djangoRequests from "../../utils/djangoRequests"
import SeparatePaymenInfoBlock from "../../payment/separatePaymentInfoBlock"

const useStyle = makeStyles(theme => ({
  wrapper: {
    background: theme.palette.background.secondary,
    touchAction: "none",
    position: "sticky",
    top: 28,
    padding: "3.12vw 2.03vw",
    borderRadius: "1.56vw 0 0 1.56vw",
    "@media(min-width: 1280px)": {
      padding: "40px 26px",
      borderRadius: "20px 0 0 20px",
    },
    "@media(max-width: 1025px)": {
      position: "unset",
      background: "transparent",

      padding: 0,
      borderRadius: 0,
    },
  },
  divider: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    background: theme.palette.background.secondaryLight,

    "@media(max-width: 1025px)": {
      top: "1.43vw",
      width: "11.87vw",
      height: "0.59vw",
      borderRadius: "0.59vw",
    },
    "@media(max-width: 767px)": {
      top: "2.89vw",
      width: "23.91vw",
      height: "1.2vw",
      borderRadius: "1.2vw",
    },
  },
  discountWrapper: {
    marginTop: "0.93vw",
    "@media(min-width: 1280px)": {
      marginTop: "12px",
    },
    "@media(max-width: 1025px)": {
      marginTop: "0.95vw",
    },
    "@media(max-width: 767px)": {
      marginTop: "1.93vw",
    },
  },
  totalPriceWrapper: {
    marginTop: "1.56vw",
    "@media(min-width: 1280px)": {
      marginTop: "20px",
    },
    "@media(max-width: 1025px)": {
      marginTop: "2.99vw",
      marginBottom: "3.35vw",
    },
    "@media(max-width: 767px)": {
      marginTop: "6.03vw",
      marginBottom: "6.76vw",
    },
  },
  normalText: {
    fontWeight: 400,
    lineHeight: 1.21,

    fontSize: "1.87vw",
    "@media(min-width: 1280px)": {
      fontSize: "24px",
    },
    "@media(max-width: 1025px)": {
      fontSize: "2.15vw",
    },
    "@media(max-width: 767px)": {
      fontSize: "4.34vw",
    },
  },
  boldText: {
    fontWeight: 700,
    lineHeight: 1.21,

    fontSize: "2.81vw",
    "@media(min-width: 1280px)": {
      fontSize: "36px",
    },
    "@media(max-width: 1025px)": {
      fontWeight: 900,
      fontSize: "2.99vw",
    },
    "@media(max-width: 767px)": {
      fontSize: "6.03vw",
    },
  },
  goRegistration: {
    width: "100%",

    marginTop: "1.56vw",
    height: "3.9vw",
    "@media(min-width: 1280px)": {
      marginTop: "20px",
      height: "50px",
    },
    "@media(max-width: 1025px)": {
      position: "fixed",
      left: "50%",
      transform: "translateX(-50%)",
      bottom: 70,
      zIndex: 99,

      width: "98.08vw",
      height: "5.99vw",
    },
    "@media(max-width: 767px)": {
      width: "97.58vw",
      height: "12.07vw",
    },
  },
  wrapperTinkoffButton: {
    width: "100%",

    marginTop: "0.78vw",
    "@media(min-width: 1280px)": {
      marginTop: 10,
    },
    "@media(max-width: 1025px)": {
      margin: "3.35vw 0",
    },
    "@media(max-width: 767px)": {
      margin: "6.76vw 0",
    },
  },
  textByeLegalEntities: {
    width: "100%",
    padding: 0,

    marginTop: "1.25vw",
    "@media(min-width: 1280px)": {
      marginTop: 16,
    },
    "@media(max-width: 1025px)": {
      marginTop: "1.91vw",
    },
    "@media(max-width: 767px)": {
      marginTop: "3.86vw",
    },
    "& span": {
      ...theme.typography.body2,

      fontWeight: 400,
      lineHeight: 1.21,
      textTransform: "none",

      fontSize: "1.25vw",
      "@media(min-width: 1280px)": {
        fontSize: 16,
      },
      "@media(max-width: 1025px)": {
        fontSize: "1.91vw",
      },
      "@media(max-width: 767px)": {
        fontSize: "3.86vw",
      },
    },
  },
  titleCreditAndDelivery: {
    fontWeight: 700,
    lineHeight: 1.21,
    marginTop: "3.12vw",
    fontSize: "1.32vw",
    "@media(min-width: 1280px)": {
      marginTop: "40px",
      fontSize: "17px",
    },
    "@media(max-width: 1025px)": {
      marginTop: "4.79vw",
      fontSize: "2.03vw",
    },
    "@media(max-width: 767px)": {
      marginTop: "9.66vw",
      fontSize: "4.1vw",
    },
  },
  textCredit: {
    cursor: "pointer",

    fontWeight: 400,
    lineHeight: 1.21,
    fontSize: "1.01vw",
    marginTop: "0.62vw",
    "@media(min-width: 1280px)": {
      fontSize: "13px",
      marginTop: "8px",
    },
    "@media(max-width: 1025px)": {
      fontSize: "1.67vw",
      marginTop: "0.95vw",
    },
    "@media(max-width: 767px)": {
      fontSize: "3vw",
      marginTop: "1.93vw",
    },
    "& span": {
      fontWeight: 600,
    },
  },
  variantDevilery: {
    fontWeight: 700,
    fontSize: "1.09vw",
    lineHeight: 1.21,
    marginTop: "0.62vw",
    "@media(min-width: 1280px)": {
      fontSize: "14px",
      marginTop: "8px",
    },
    "@media(max-width: 1025px)": {
      fontSize: "1.67vw",
      marginTop: "0.95vw",
    },
    "@media(max-width: 767px)": {
      fontSize: "3.38vw",
      marginTop: "1.93vw",
    },
    "& span": {
      fontWeight: 400,
      color: "#000000",
      "-webkit-text-fill-color": "#000000",
    },
  },
  rassrochkaSpan: {
    marginLeft: "0.625vw",
    "@media(min-width: 1280px)": {
      marginLeft: "8px",
    },
    "@media(max-width: 1025px)": {
      marginLeft: "0.95vw",
    },
    "@media(max-width: 767px)": {
      marginLeft: "1.93vw",
    },
  },
  mokka: {
    display: "inline-block",

    margin: "0 0.625vw",
    height: "1.32vw",
    "@media(min-width: 1280px)": {
      margin: "0 8px",
      height: "17px",
    },
    "@media(max-width: 1025px)": {
      margin: "0 0.95vw",
      height: "2.03vw",
    },
    "@media(max-width: 767px)": {
      margin: "0 1.93vw",
      height: "3.62vw",
    },
  },
  mokkaInfo: {
    display: "inline-block",
    cursor: "pointer",

    marginLeft: "0.31vw",
    height: "1.32vw",
    width: "1.32vw",
    "@media(min-width: 1280px)": {
      marginLeft: "4px",
      height: "17px",
      width: "17px",
    },
    "@media(max-width: 1025px)": {
      marginLeft: "0.47vw",
      height: "2.03vw",
      width: "2.03vw",
    },
    "@media(max-width: 767px)": {
      marginLeft: "0.96vw",
      height: "4.1vw",
      width: "4.1vw",
    },
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mokkaBaner: {
    position: "relative",
    background: "center / cover no-repeat url('/svg/mokkaBaner.png')",

    width: "58.12vw",
    height: "37.18vw",
    "@media(min-width: 1280px)": {
      width: 744,
      height: 476,
    },
    "@media(max-width: 1025px)": {
      width: "72.58vw",
      height: "46.43vw",
    },
    "@media(max-width: 767px)": {
      width: "97vw",
      height: "62.05vw",
    },
  },
  mokkaCross: {
    padding: 0,
    background: "transparent",
    minHeight: 0,
    minWidth: 0,
    border: "none",
    outline: "none",
    cursor: "pointer",

    position: "absolute",
    top: "7.77%",
    right: "3.76%",

    width: "3.225%",
    height: "5.042%",
  },
  paymentMessageContainer: {
    display: "flex",
    alignItems: "center",
    gap: 10,

    marginTop: 40,

    "@media(max-width: 1025px)": {
      marginBottom: 40,
    },
  },

  paymentMessageTextBlock: {
    display: "flex",
    flexDirection: "column",
    gap: 0,
  },

  paymentMessageText: {
    marginTop: 2,

    fontWeight: 'bold',
    fontSize: "1.1vw",

    "@media(min-width: 1280px)": {
      fontSize: "17px",
    },

    "@media(max-width: 1025px)": {
      fontSize: "2vw",
    },

    "@media(max-width: 767px)": {
      fontSize: "2.5vw",
    },
  },

  paymentMessageDetailsText: {
    marginTop: 2,

    fontSize: "14px",
    color: "#333",

    "@media(max-width: 1280px)": {
      fontSize: "1.2vw",
    },

    "@media(max-width: 1025px)": {
      fontSize: "1.6vw",
    },

    "@media(max-width: 767px)": {
      fontSize: "2.5vw",
    },

  },

  paymentMessageLink: {
    cursor: "pointer",
    color: "#291AD5",
    textDecoration: "underline",
  },
}))

/**
 * Блок с ценой продуктов на странице корзины
 * @module src/components/cartPage/priceBlock
 * @param {Object} props - объект свойств компонента React
 * @param {Object[]} props.products - массив объектов продуктов полученых из prismic
 */
export default function PriceBlock({ products, prismicVariantPay, cities }) {
  const [productsState, setProductsState] = useState(products);
  const [isSeparatePaymentBlockShown, setIsSeparatePaymentBlockShown] = useState(false);

  const state = React.useContext(GlobalStateContext)

  useEffect(() => {
    let uids = products.map(prod => prod.uid)
    djangoRequests.getPrice(uids).then(res => {
      setProductsState(p => {
        return p.map(prod => {
          if (res.products[prod.uid] === false) {
            return prod
          } else {
            return { ...prod, data: { ...prod.data, price: res.products[prod.uid] } }
          }
        })
      })
    })

    if (productsState.length === 0) {
      return;
    }

    // Получаю продавца первого товара и узнаю юридическое он лицо или нет. 
    // Если продавца нет - то продавец криптон, то есть юридическое
    const seller = productsState[0].data.body?.find((bodyItem) => bodyItem.slice_type === 'seller');
    const isSellerLegalEntity = seller ? seller.primary.seller.document.data.is_legal_entity : true;

    const doAllSellersHaveSameType = productsState.every((product) => {
      const seller = product.data.body?.find((bodyItem) => bodyItem.slice_type === 'seller');
      const isCurrentProductSellerLegalEntity = seller ? seller.primary.seller.document.data.is_legal_entity : true;

      console.log('isSellerLegalEntity :', isSellerLegalEntity);
      console.log('isCurrentProductSellerLegalEntity :', isCurrentProductSellerLegalEntity);

      return isSellerLegalEntity === isCurrentProductSellerLegalEntity;
    });

    console.log("doAllSellersHaveSameType", doAllSellersHaveSameType);

    setIsSeparatePaymentBlockShown(!doAllSellersHaveSameType);
  }, [products])
  const classes = useStyle()

  const mobile = useMediaQuery("(max-width: 1025px)")

  // Варианты оплаты (для тестирования):
  // const prismicPayVariants = {
  //   'online': true,
  //   'upon_receipt': true,
  //   'tinkoff_credit': true,
  //   'vsegda_da_credit': true,
  //   'poscredit_credit': true,
  //   'mokka_payment': true,
  //   'payment_for_legal_entities': true
  // }

  // Варианты оплаты:
  let prismicPayVariants = {}
  prismicVariantPay.forEach(item => {
    let payName;
    switch (item.pay_name.text) {
      case 'Тинькофф онлайн оплата':
        payName = 'online'
        break
      case 'Тинькофф Кредит':
        payName = 'tinkoff_credit'
        break
      case 'Мокка рассрочка':
        payName = 'mokka_payment'
        break
      case 'При получении':
        payName = 'upon_receipt'
        break
      case 'Всегда.да':
        payName = 'vsegda_da_credit'
        break
      case 'Банковский перевод для юр лиц':
        payName = 'payment_for_legal_entities'
        break
      case "Денум кредит":
        payName = "denum_credit"
        break
    }
    prismicPayVariants[payName] = item.visibility
  })

  const [showMokkaInfo, setShowMokkaInfo] = React.useState(false)
  const [showMokkaIframe, setShowMokkaIframe] = React.useState(false)

  const summPrice = productsState.reduce((summ, product) => {
    return summ + product?.data.price * state.inCart(product)
  }, 0)
  const summOldPrice = productsState.reduce((summ, product) => {
    return (
      summ +
      (product?.data.old_price ?? product?.data.price) * state.inCart(product)
    )
  }, 0)

  function goRegistration(LegalEntities) {
    console.log("productsState", productsState);
    const positions = productsState.reduce((order, product, i) => {
      const num = i + 1
      const name = product?.data.name
      const count = state.inCart(product)
      const position = `${num}. Товар: ${name}; Количество: ${count}. \n`
      return order + position
    }, "")

    const prodDataArr = []
    productsState.map(product => {
      const name = product?.data.name
      const count = state.inCart(product)
      const seller = product?.data.body?.find((bodyItem) => bodyItem.slice_type === "seller");

      const jsonData = {
        product_name: name,
        product_uid: product?.uid,
        quantity: count,
        price: product?.data.price,
        repair: product?.repair,
        repair_category: product?.repair ? product?.data.category : undefined,
        repair_services: product?.data.services?.map(
          service => service.primary.name
        ),
        seller: seller?.primary.seller.document.data,
      }
      prodDataArr.push(jsonData)
    })

    localStorage.setItem(
      "order",
      JSON.stringify({
        positions,
        price: summPrice,
        sale: summPrice - summOldPrice,
        allProductsJson: prodDataArr,
      })
    )

    if (LegalEntities) {
      navigate("/ordering", { state: { legalEntities: true } })
    }
  }


  // данные по кредиту и рассрочке
  const credit = productsState[0].data.credit?.document?.data ?? null

  const ps = +credit?.percent.replace(",", ".") / 12 / 100
  const creditValue =
    credit?.percent && credit?.months_1
      ? summPrice * (ps / (1 - Math.pow(1 + ps, -credit?.months_1)))
      : null

  // преобразуем цену
  function priceMod(value) {
    let price = "" + value
    let length = price.length
    for (let i = 1; i < length; i++) {
      if (i % 3 === 0) {
        price = price.slice(0, length - i) + " " + price.slice(length - i)
      }
    }
    return price
  }

  function switchShowMokkaInfo(e) {
    e.preventDefault()
    setShowMokkaInfo(!showMokkaInfo)
  }

  function switchShowMokkaIframe(e) {
    if (e.defaultPrevented) return
    setShowMokkaIframe(!showMokkaIframe)
  }

  const initCity = localStorage.getItem('city')
  const checkPickupCity = () => {
    if (initCity !== '') {
      var cityIndex = cities.findIndex(item => item.city === initCity)
      return cities[cityIndex].pickup
    }
  }
  const lessDelivery = productsState.reduce((min, item) => item.data.delivery?.document?.data.variants.length > min.data.delivery?.document?.data.variants.length ? min : item)
  // варианты доставки
  const devilery = lessDelivery.data.delivery?.document?.data.variants.filter(item => {
    if (!checkPickupCity()) {
      if (item.name === "Самовывоз") {
        return false
      }
    }
    return true
  }) ?? []

  return (
    <div className={classes.wrapper}>

      {mobile ? <div className={classes.divider} /> : null}

      <Grid container justify="space-between">
        <Typography className={classes.normalText}>Стоимость</Typography>

        <Typography className={classes.normalText}>{`${priceMod(
          summOldPrice
        )} ₽`}</Typography>
      </Grid>

      <Grid
        container
        justify="space-between"
        className={classes.discountWrapper}
      >
        <Typography className={classes.normalText}>Скидка</Typography>

        <Typography
          className={classes.normalText}
          style={{ color: "#FF5B5B" }}
        >{`${priceMod(summPrice - summOldPrice)} ₽`}</Typography>
      </Grid>

      <Grid
        container
        justify="space-between"
        className={classes.totalPriceWrapper}
      >
        <Typography className={classes.boldText}>Итого</Typography>

        <Typography className={classes.boldText}>{`${priceMod(
          summPrice
        )} ₽`}</Typography>
      </Grid>

      <div className={classes.goRegistration}>
        <GoRegistration text="Перейти к оформлению" onClick={goRegistration} />
      </div>

      {!prismicPayVariants.tinkoff_credit || state.servicesAvailable() ? null : (
        <div className={classes.wrapperTinkoffButton}>
          <Tinkoff items={state.cart} />
        </div>
      )}
      {!prismicPayVariants.denum_credit || state.servicesAvailable() ? null : (
        <div className={classes.wrapperTinkoffButton}>
          <DenumButton items={state.cart} />
        </div>
      )}

      {isSeparatePaymentBlockShown && (
        <SeparatePaymenInfoBlock />
      )}

      {(credit || devilery.length) && !mobile ? (
        <>
          {!prismicPayVariants.tinkoff_credit && !prismicPayVariants.mokka_payment ? null : (
            <>
              {!prismicPayVariants.tinkoff_credit && prismicPayVariants.mokka_payment &&
                summPrice >= 100000 ||
                summPrice < 5000 ? null : (
                <Typography className={classes.titleCreditAndDelivery}>
                  Оплата авансом и кредит
                </Typography>
              )}


              {!prismicPayVariants.tinkoff_credit ? null : (
                <Typography
                  hidden={!creditValue}
                  className={classes.textCredit}
                  style={{ cursor: "inherit" }}
                >
                  Кредит в Тинькофф от{" "}
                  <span>{priceMod(Math.trunc(creditValue))} ₽/мес</span>
                </Typography>
              )}

              {!prismicPayVariants.mokka_payment ||
                summPrice >= 100000 ||
                summPrice < 5000 ? null : (
                <Typography
                  role="button"
                  onClick={switchShowMokkaIframe}
                  hidden={!credit.months_2}
                  className={classes.textCredit}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Оплата авансом
                  <span className={classes.rassrochkaSpan}>
                    от {priceMod(Math.trunc(summPrice / credit.months_2))} ₽/мес
                  </span>
                  <span className={classes.mokka}>
                    <Mokka />
                  </span>
                  |
                  <span
                    role="button"
                    onClick={switchShowMokkaInfo}
                    className={classes.mokkaInfo}
                  >
                    <MokkaInfo aria-label="mokka info" />
                  </span>
                </Typography>
              )}
            </>
          )}
        </>
      ) : null}

      <Modal
        open={showMokkaIframe}
        onClose={() => setShowMokkaIframe(false)}
        className={classes.modal}
      >
        <MokkaIframeRegistration onClose={() => setShowMokkaIframe(false)} />
      </Modal>

      <Modal
        open={showMokkaInfo}
        onClose={() => setShowMokkaInfo(false)}
        className={classes.modal}
      >
        <div className={classes.mokkaBaner}>
          <button
            onClick={() => setShowMokkaInfo(false)}
            className={classes.mokkaCross}
          >
            <MokkaCross />
          </button>
        </div>
      </Modal>

      {devilery.length && !mobile ? (
        <>
          <Typography className={classes.titleCreditAndDelivery}>
            Способ получения
          </Typography>

          {devilery.map(variant => (
            <Typography
              variant="body2"
              key={variant.name}
              className={classes.variantDevilery}
            >
              {variant.name} <span>{variant.description}</span>
            </Typography>
          ))}
        </>
      ) : null}
    </div>
  )
}
