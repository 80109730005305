import React from "react"
import Popup from "."
import { makeStyles } from "@material-ui/core"

import CheckIcon from "../../../static/svg/check.svg"
import InfoIcon from "../../../static/svg/info.svg"
import { GatsbyImage } from "gatsby-plugin-image"

const useStyle = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 16,

    alignItems: "center",

    width: 388,
    maxHeight: '80vh',
    boxSizing: "border-box",

    overflowY: 'auto',
    overflowX: 'visible',

    "@media(max-width: 460px)": {
      width: '100%',
    },
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },

  successIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    width: 24,
    height: 24,

    backgroundColor: '#6FCF97',
    borderRadius: 40,
  },

  title: {
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: 2,
    color: "#000000",
    flex: "none",

    "@media(max-width: 1280px)": {
      fontSize: "1.8vw",
    },

    "@media(max-width: 1025px)": {
      fontSize: "2.4vw",
    },

    "@media(max-width: 767px)": {
      fontSize: 20,
    },
  },

  infoBlock: {
    width: "100%",
    backgroundColor: "#F2F2F2",
    borderRadius: 16,

    display: "flex",
    alignItems: "center",
    padding: 12,
    gap: 12,
  },

  text: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: 1.5,
    color: "#000000",

    "@media(max-width: 1280px)": {
      fontSize: "1.4vw",
    },

    "@media(max-width: 1025px)": {
      fontSize: "1.8vw",
    },

    "@media(max-width: 767px)": {
      fontSize: 14,
    },
  },

  successImage: {
    minWidth: 128,
    minHeight: 128,
  },

  itemsBlock: {
    display: "flex",
    flexDirection: 'column',
    padding: "12px 0",
    width: '100%',
    gap: 12,
  },

  itemsTitle: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "19px",
  },

  itemsHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 12,
    width: "100%",
  },

  items: {
    display: "flex",
    flexDirection: "column",
    padding: "12px 0",
    gap: 10,
  },

  itemBlock: {
    display: "flex",
    alignItems: 'center',
    gap: 12,
    width: "100%",

    "@media(max-width: 1280px)": {
      gap: "1.4vw",
    },

    "@media(max-width: 1025px)": {
      gap: "1.8vw",
    },

    "@media(max-width: 767px)": {
      gap: 12,
    },

  },

  imageBlock: {
    display: "flex",
    alignItems: 'center',
    width: 32,
    height: 32,
    minWidth: 32,
    minHeight: 32,
  },

  button: {
    color: "white",
    fontSize: 16,
    fontWeight: 'bold',

    padding: "8px 24px",

    background: theme.palette.background.accent,
    textTransform: "none",

    borderRadius: "0.93vw",
    "@media(min-width: 1280px)": {
      borderRadius: "12px",
    },
    "@media(max-width: 1025px)": {
      borderRadius: "4.79vw",
    },
    "@media(max-width: 767px)": {
      borderRadius: "4.83vw",
    },
    "&:hover": {
      background: theme.palette.background.accent,
      boxShadow: "0px 0px 0px 4px #D2D0E9",
    },
  },
}))

const PaymentPopup = ({ isOpen, paymentData }) => {
  const classes = useStyle()

  if (!isOpen) {
    return null;
  }

  return (
    <Popup isOpen={isOpen}>
      <div className={classes.container}>
        <div className={classes.header}>
          <h2 className={classes.title}>Заказ создан</h2>
          <div className={classes.successIcon}>
            <CheckIcon width={12} />
          </div>
        </div>

        <div className={classes.infoBlock}>
          <InfoIcon width={24} />
          <p className={classes.text}>
            Оплата заказа будет разделена на 2 части
          </p>
        </div>

        <div className={classes.itemsBlock}>
          <div className={classes.itemsHeader}>
            <p className={classes.itemsTitle}>Товары юридических лиц</p>
            <a href={paymentData.url_1} target="_blank"><button className={classes.button}>Оплатить</button></a>
          </div>
          <div className={classes.items}>
            {paymentData.itemsList_1.map(({ Name: name, gatsbyImageData }) => (
              <div className={classes.itemBlock}>
                <div className={classes.imageBlock}>
                  <GatsbyImage
                    width={32}
                    loading="eager"
                    image={gatsbyImageData}
                    alt={name}
                    className={classes.wrapperImg}
                  />
                </div>
                <p className={classes.text}>{name}</p>
              </div>
            ))}
          </div>
        </div>

        <div className={classes.itemsBlock}>
          <div className={classes.itemsHeader}>
            <p className={classes.itemsTitle}>Товары физических лиц</p>
            <a href={paymentData.url_2} target="_blank"><button className={classes.button}>Оплатить</button></a>
          </div>
          <div className={classes.items}>
            {paymentData.itemsList_2.map(({ Name: name, gatsbyImageData }) => (
              <div className={classes.itemBlock}>
                <div className={classes.imageBlock}>
                  <GatsbyImage
                    width={32}
                    loading="eager"
                    image={gatsbyImageData}
                    alt={name}
                    className={classes.wrapperImg}
                  />
                </div>
                <p className={classes.text}>{name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default PaymentPopup
