import React, { useState } from "react"
import { makeStyles, Typography } from "@material-ui/core"

import PaymentDetailsPopup from "../../popup/paymentDetailsPopup"
import CautionIcon from "../../../../static/svg/caution.svg"

const useStyle = makeStyles(theme => ({
  paymentMessageContainer: {
    display: "flex",
    alignItems: "center",
    gap: 10,

    marginTop: 40,

    "@media(max-width: 1025px)": {
      marginBottom: 40,
    },
  },

  paymentMessageTextBlock: {
    display: "flex",
    flexDirection: "column",
    gap: 0,
  },

  paymentMessageText: {
    marginTop: 2,

    fontWeight: "bold",
    fontSize: 14,

    "@media(max-width: 1025px)": {
      fontSize: "2vw",
    },

    "@media(max-width: 767px)": {
      fontSize: "2.5vw",
    },
  },

  paymentMessageDetailsText: {
    marginTop: 2,

    fontSize: "14px",
    color: "#333",

    "@media(max-width: 1280px)": {
      fontSize: "1.2vw",
    },

    "@media(max-width: 1025px)": {
      fontSize: "1.6vw",
    },

    "@media(max-width: 767px)": {
      fontSize: "2.5vw",
    },
  },

  paymentMessageLink: {
    cursor: "pointer",
    color: "#291AD5",
    textDecoration: "underline",
  },
}))

export default function SeparatePaymenInfoBlock() {
  const classes = useStyle()
  const [isPaymentDetailsPopupOpen, setIsPaymentDetailsPopupOpen] = useState(
    false
  )

  function handlePaymentDetailClick() {
    setIsPaymentDetailsPopupOpen(true)
  }

  function handlePaymentPopupClose() {
    setIsPaymentDetailsPopupOpen(false)
  }

  return (
    <>
      <PaymentDetailsPopup
        handleClose={handlePaymentPopupClose}
        isOpen={isPaymentDetailsPopupOpen}
      />

      <div className={classes.paymentMessageContainer}>
        <CautionIcon height={24} width={24} />

        <div className={classes.paymentMessageTextBlock}>
          <Typography className={classes.paymentMessageText}>
            Корзина будет оплачена в 2 этапа.{" "}
            <Typography
              variant="span"
              className={classes.paymentMessageLink}
              onClick={handlePaymentDetailClick}
            >
              Подробнее
            </Typography>
          </Typography>
        </div>
      </div>
    </>
  )
}
